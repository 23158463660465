import { UniversalHeader as UniversalHeaderComponent } from '@amzn/sitc-frontend/components';
import { AlertContext, useCommonUserPreferencesUpdate, useCommonUserPreferencesUpdateStatus } from '@amzn/sitc-frontend/contexts';
import { useFeature } from '@amzn/sitc-frontend/hooks';
import type { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import { CommonUserPreferencesUpdateStatus } from '@amzn/sitc-frontend/types';
import Grid from '@mui/material/Grid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { microAppName } from '../configs/app';
import { EventNames } from '../configs/events';
import { UniversalHeaderContext } from '../contexts/UniversalHeader';
import { UserDataContext } from '../contexts/UserData';
import { useDefaultAppSwitcherContent } from '../hooks/app-switcher';
import { useMicroAppConfig } from '../hooks/context/micro-app-config';
import { useEventBus } from '../hooks/event-bus';
import { useDefaultUserProfileContent } from '../hooks/user-profile';
import type { ConfiguredEventPayload, ConfigureEventPayload } from '../schemas/configure-event/ts-schema';
import type { SubscribeCallback } from '../types/events';
import { getUniversalHeaderProps } from '../utils/universal-header';
import { SkeletonLoader } from './commons/SkeletonLoader';
import { Base } from './layouts/Base';
import { UniversalHeaderWithSearch } from './UniversalHeaderWithSearch';

export const UniversalHeaderShell: React.FC<MicroAppInitializationProps> = (props) => {
  const { appConfig } = useMicroAppConfig();
  const { t } = useTranslation();
  const eventBus = useEventBus();
  const themeToggleEnabled = useFeature('enableThemeToggle');
  const defaultAppSwitcherContent = useDefaultAppSwitcherContent();
  const [enableAlert, setEnableAlert] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setAlert } = useContext(AlertContext);
  const { universalHeaderConfig, setUniversalHeaderConfig } = useContext(UniversalHeaderContext);
  const { userData, loading } = useContext(UserDataContext);
  const { patchCommonUserPreferences } = useCommonUserPreferencesUpdate();
  const { getCommonUserPreferencesUpdateStatus, updateCommonUserPreferencesUpdateStatus } = useCommonUserPreferencesUpdateStatus();
  const updateStatus = getCommonUserPreferencesUpdateStatus();
  const setUpdateStatus = updateCommonUserPreferencesUpdateStatus();
  const defaultUserProfileContent = useDefaultUserProfileContent(userData);

  useEffect(() => {
    // Fallback if universal header has not been configured in a certain amount of time
    const fallbackTimeoutId = setTimeout(() => {
      if (!universalHeaderConfig) {
        setUniversalHeaderConfig({});
      }
    }, appConfig?.fallback?.fallbackTimeMs);

    return () => clearTimeout(fallbackTimeoutId);
  }, [appConfig, universalHeaderConfig]);

  useEffect(() => {
    const handleConfiguration: SubscribeCallback<ConfigureEventPayload> = (event) => {
      const { payload } = event;

      if (payload) {
        const { appName, config } = payload;
        setUniversalHeaderConfig(config);
        eventBus.publish<ConfiguredEventPayload>(EventNames.Configured, {
          payload: {
            appName,
            success: true,
          },
        });
      }
    };

    const appRegistered = eventBus.subscribe(EventNames.Configure, handleConfiguration, { replay: true });

    return () => {
      appRegistered?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (enableAlert && updateStatus?.status === CommonUserPreferencesUpdateStatus.Error && updateStatus?.source === microAppName) {
      setAlert({
        content: t('app.alert.theme.error'),
        severity: 'error',
      });
    } else {
      setAlert(undefined);
    }
  }, [updateStatus, enableAlert]);

  useEffect(() => {
    setTimeout(() => setEnableAlert(false), 6000);
  }, [enableAlert]);

  if (!universalHeaderConfig || loading) {
    return (
      <Grid container>
        <Grid item>
          <SkeletonLoader />
        </Grid>
      </Grid>
    );
  }

  const onThemeChange = (theme: string): void => {
    setEnableAlert(true);
    setUpdateStatus(undefined);
    patchCommonUserPreferences({ theme: { name: theme } });
  };

  const { originSearch, ...restUniversalHeaderProps } = universalHeaderConfig;
  const universalHeaderProps = getUniversalHeaderProps({
    universalHeaderConfig: restUniversalHeaderProps,
    onThemeChange,
    themeToggleEnabled,
    defaultAppSwitcherContent,
    defaultUserProfileContent,
  });

  if (originSearch?.enable) {
    return (
      <Base
        header={
          <UniversalHeaderWithSearch
            getSearchResultNavigatePath={originSearch.getSearchResultNavigatePath}
            originSearchFilters={originSearch?.filters}
            originSearchTitleTypes={originSearch?.titleTypes}
            universalHeaderProps={universalHeaderProps}
            {...props}
          />
        }
      />
    );
  }

  return <Base header={<UniversalHeaderComponent fixPosition {...universalHeaderProps} />} />;
};
