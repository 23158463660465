import type { UniversalHeaderProps } from '@amzn/sitc-frontend/components';
import { UniversalHeader } from '@amzn/sitc-frontend/components';
import { useMicroApp } from '@amzn/sitc-frontend/hooks';
import type { MicroAppInitializationProps, OriginSearchAppConfig } from '@amzn/sitc-frontend/types';
import type { Channel } from '@amzn/sitc-frontend-micro-app-sdk-core/radio';
import { Domain } from '@amzn/studios-domain-authority-types/isdg';
import type { Filter, OriginSearch, SearchResult } from '@amzn/studios-origin-search-app-shared-types';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SingleSpaContext } from 'single-spa-react';

interface Props extends MicroAppInitializationProps {
  getSearchResultNavigatePath: (searchResult: SearchResult) => string | Promise<string>;
  originSearchFilters?: Filter[];
  originSearchTitleTypes?: OriginSearch['titleTypes'];
  universalHeaderProps: UniversalHeaderProps;
}

export const UniversalHeaderWithSearch: React.FC<Props> = ({
  getSearchResultNavigatePath,
  originSearchFilters,
  originSearchTitleTypes,
  universalHeaderProps,
  ...props
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { appId, radio } = props;

  const searchRegistrationKey = `${appId}#header-origin-search`;
  const originSearchChannel = radio.getChannel('origin-search') as unknown as Channel;

  const onSearchResultClick = async (searchResult: SearchResult) => {
    const path = await Promise.resolve(getSearchResultNavigatePath(searchResult));
    navigate(path);
  };

  useMicroApp<OriginSearchAppConfig>({
    loadProps: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      microAppContainer: () => document.querySelector('.header-origin-search-container')!,
      microAppInitializationProps: {
        ...props,
        registrationKey: searchRegistrationKey,
      },

      microAppName: 'amzn-studios-origin-search-app',
      singleSpaContext: SingleSpaContext,
    },
    registrationProps: {
      channel: originSearchChannel,
      registrationKey: searchRegistrationKey,
      registrationOptions: {
        appId,
        theme,
        getSearchConfiguration: () => ({
          renderOptions: {},
          searchOptions: {
            domains: [Domain.Title],
            ...(originSearchTitleTypes?.length && {
              domainConfigs: {
                [Domain.Title]: {
                  titleTypes: originSearchTitleTypes,
                },
              },
            }),
            eventHandlers: {
              onSearchResultClick,
            },
            filters: originSearchFilters,
          },
        }),
      },
    },
  });

  return <UniversalHeader fixPosition enableOriginSearch {...universalHeaderProps} />;
};
